import React from "react";

function Title(props) {
  return (
    <div className={`title text-center my-16 mt-12 md:my-20 xl:my-24 ${props.top}`}>

      {props.sub && (
        <div className={`inline-block mb-4 lg:mb-8 text-root py-2 px-4 rounded-full pointer-events-none bg-${props.colour}`}>
          {props.sub}
        </div>
      )}

      <h1
        className={`mx-auto font-medium text-4xl md:text-5xl xl:text-6xl leading-none ${props.width}`}
        dangerouslySetInnerHTML={{ __html: props.title }}>
      </h1>

      { props.children }

    </div>
  );
}

export default Title;