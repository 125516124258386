import React from "react"
import Layout from "../components/layout";
import Promo from "../components/promo";
import Feature from "../components/feature";
import Title from "../components/title";
import { Helmet } from 'react-helmet';
import { Link } from "gatsby";

import PDF from "../../static/icons/pdf.inline.svg";
import Overdue from "../../static/icons/overdue.inline.svg";
import Email from "../../static/icons/email.inline.svg";
import Currency from "../../static/icons/currency.inline.svg";
import Arrow from "../../static/icons/arrow.inline.svg";

const Product = ({ location }) => (

    <Layout 
      path={location.pathname}>

      <Helmet
        bodyAttributes={{
          class: 'bg-gray-100 text-root'
        }} />

      <Title
        colour="green-500"
        title="Automate your invoices"
        width="max-w-xs md:max-w-sm xl:max-w-xl">

        <p className="max-w-lg leading-relaxed mx-auto my-8 text-dark text-center text-lg md:text-xl">
          Keep track of the work you've already invoiced, and the work you still need to invoice.
        </p>

      </Title>

      <div className="max-w-8xl xl:px-12 mx-auto mb-16 md:mb-20">

        <div className="relative pb-16/9 rounded-lg overflow-hidden">
          <video className="outline-none absolute w-full h-full object-cover" autoPlay muted loop playsinline>
            <source src="/videos/feature_invoice.mp4" type="video/mp4" />
          </video>
        </div>

      </div>

      <div className="lg:container max-w-6xl mx-auto md:text-lg">

        <div className="lg:flex lg:space-x-8">

          <div className="lg:w-3/4 mx-auto space-y-4 sm:space-y-8">

            <Feature
              icon={<Overdue />}
              title="A simple reminder system."
              text="Forgetting what you've invoiced can be costly. Especially if you're using separate apps for your schedule and your invoicing. With Slate you don't need to guess. We keep track of the work you add to your schedule and make sure you never forget to invoice it."
              colour="green-500" />

            <Feature
              icon={<PDF />}
              title="Beautiful PDF for every invoice."
              text="Get a beautiful PDF for every invoice you raise. Send it to your clients from Slate, or download to email it to them yourself."
              colour="green-500" />

            <Feature
              icon={<Currency />}
              title="Multi currency support built-in."
              text="Slate makes it simple to create invoices in different currencies, so if you've got a client based in the UK and one in the US
              we'll automatically generate invoices for their local currency."
              colour="green-500" />

            <Feature
              icon={<Email />}
              title="Send to your clients inbox."
              text="Whenever you generate an invoice, Slate gives you the option of sending it directly to your clients inbox or downloading
              a PDF that you can send yourself."
              colour="green-500" />

          </div>

        </div>

      </div>

      <Promo theme="dark" />

    </Layout>
)

export default Product;