import React from "react";

const Promo = ({theme}) => {

var hr;
var btn;
var btn__outline;
var bg;

  if(theme === 'light') {
    hr = 'border-box_dark';
    btn = 'btn hover:bg-root hover:border-root hover:text-white';
    btn__outline = 'btn btn__outline hover:text-dark hover:border-dark';
    bg = 'bg-light';
  } else {
    hr = 'border-box';
    btn = 'btn hover:bg-light hover:border-transparent hover:text-white';
    btn__outline = 'btn btn__outline hover:text-light hover:border-light';
    bg = 'bg-box';
  }

	return (
    <div className={`mt-8 sm:mt-16 md:mt-24 ${bg}`}>

      <hr className={hr} />

      <p className="mt-8 sm:mt-16 md:mt-24 text-center text-xl md:text-2xl font-medium leading-tight">30-day trial. No card required.</p>

      <div className="mt-10 space-y-4 flex flex-col items-center sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
        <a href="https://app.heyslate.com/register" className={btn} target="_blank" rel="noopener noreferrer">Get started for free</a>
      </div>

    </div>
	)

}

export default Promo;