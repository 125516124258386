import React from "react";

function Feature(props) {
  return (
    <div className={`rounded-xl ${props.tight && 'pb-0 lg:pb-0'}`}>

      <figure className={`bg-${props.colour} text-white w-6 h-6 rounded-full flex justify-center items-center`}>{props.icon}</figure>

      <h3 className="max-w-sm text-xl font-light my-4 leading-tight text-dark">{props.title}</h3>

      <p className="text-dark mt-4">{props.text}</p>

      {props.image && (
        <div className="mt-8 md:mt-16">
          <img src={props.image} className="w-full" alt={props.title} />
        </div>
      )}
    </div>
  );
}

export default Feature;